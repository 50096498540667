import React, { useRef, useState } from "react";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import akdaş from "./Port/akdaş.svg";
import deal from "./Port/deal.svg";
import pilates from "./Port/pilates.svg";
import romac from "./Port/romac.svg";

const PortfolioSection = () => {
    const containerRef = useRef(null);

    // Set up useScroll WITHOUT the `once` option so it updates continuously.
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start center", "center center"],
    });

    // Flag to indicate that the user has scrolled at least once.
    const [hasScrolledOnce, setHasScrolledOnce] = useState(false);

    // Listen to scroll progress changes.
    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        // If the scroll progress exceeds a small threshold and we haven’t already marked it,
        // then mark that the user has scrolled.
        if (!hasScrolledOnce && latest > 0.01) {
            setHasScrolledOnce(true);
        }
    });

    const portfolioItems = [
        {
            id: 1,
            image: akdaş,
            title: "Akdaş",
            description:
                "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 2,
            image: deal,
            title: "Dealn",
            description:
                "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 3,
            image: romac,
            title: "Romac",
            description:
                "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 4,
            image: pilates,
            title: "Take Care Pilates",
            description:
                "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
    ];

    // Define the final positions you want each item to animate to.
    // (You can adjust these values as needed.)
    const finalPositions = [
        { x: -300, y: -300 },
        { x: 300, y: -300 },
        { x: -300, y: 300 },
        { x: 300, y: 300 },
    ];

    return (
        <div ref={containerRef} className="h-full relative bg-white w-5/6 mx-auto">
            {/* Desktop Layout */}
            <div className="sticky top-0 h-full flex flex-col items-center justify-center overflow-hidden max-md:hidden">
                <p className="text-[#1F1F1F] text-[64px] font-semibold text-center mt-8">
                    Portfolyomuz
                </p>
                <div
                    className="relative w-full h-[160vh] flex items-center justify-center"
                    style={{ perspective: 2000 }}
                >
                    {portfolioItems.map((item, index) => (
                        <motion.div
                            key={item.id}
                            className="absolute w-[500px] rounded-xl overflow-hidden shadow-2xl cursor-pointer"
                            style={{ transformStyle: "preserve-3d", transformOrigin: "center center" }}
                            // Initially, each item is in its default state (centered, no rotation, full scale).
                            initial={{ x: 0, y: 0, scale: 1, rotate: 0 }}
                            // Once the user scrolls for the first time, animate to the final state.
                            animate={
                                hasScrolledOnce
                                    ? {
                                        x: finalPositions[index].x,
                                        y: finalPositions[index].y,
                                        scale: 1,
                                        rotate: 0,
                                    }
                                    : {}
                            }
                            transition={{ duration: 0.8, ease: "easeOut" }}
                        >
                            <img
                                src={item.image}
                                alt={item.title}
                                className="w-full h-full object-cover"
                            />
                            <div className="p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                    {item.title}
                                </h2>
                                <p className="text-gray-600 text-sm leading-relaxed">
                                    {item.description}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>

            {/* Mobile Layout */}
            <p className="md:hidden text-black text-[40px] font-semibold text-left mr-auto mt-[5vh]">
                Portfolio
            </p>
            <div className="md:hidden w-full overflow-x-auto flex gap-4 px-4 flex-row justify-start items-center min-h-fit overflow-y-hidden">
                {portfolioItems.map((item) => (
                    <motion.div
                        key={item.id}
                        className="flex-shrink-0 w-[350px] rounded-xl overflow-hidden cursor-pointer"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            className="w-full h-[260px] object-cover"
                        />
                        <div className="p-2">
                            <h2 className="text-lg font-semibold text-gray-800 mb-2 text-left">
                                {item.title}
                            </h2>
                            <p className="text-gray-600 text-sm leading-relaxed text-left">
                                {item.description}
                            </p>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default PortfolioSection;
