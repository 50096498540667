import React from "react";
import blog1 from "./Assets/blog1.svg";
import blog2 from "./Assets/blog2.svg";
import blog3 from "./Assets/blog3.svg";
import footerLogo from "./Assets/footerLogo.svg";
import line from "./Assets/line.svg";
import { motion } from "framer-motion";
import { Typewriter } from "./typewriter";
import footerLine from "./Assets/footerLine.svg";
import { FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";
const Footer = () => {
    const blogPosts = [
        {
            id: 1,
            image: blog1,
            title: "Yapay Zeka ve Makine Öğrenimi ile Dijital Pazarlama :Geleceğin Stratejileri Giriş",
            description: "Dijital pazarlama dünyasında yenilikler ve gelişmeler hız kesmeden devam ediyor.",
        },
        {
            id: 2,
            image: blog2,
            title: "Sosyal Medyada Video İçeriklerinin Gücü: Markanızı Nasıl Yükseltebilirsiniz?",
            description: "Sosyal medya platformlarında video içerikleri, kullanıcılar arasında hızla popülerlik...",
        },
        {
            id: 3,
            image: blog3,
            title: "2024'te SEO Trendleri: Başarılı Bir Strateji İçin Bilmeniz Gerekenler",
            description: "SEO (Arama Motoru Optimizasyonu), dijital pazarlamanın en önemli bileşenlerinden....",
        },
    ];
    const cardVariants = (index) => ({
        hidden: {
            opacity: 0,
            x: index % 2 === 0 ? -100 : 100, // Slide in from left if even, right if odd
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut",
            },
        },
    });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2, // Delay between cards
            },
        },
    };
    return (
        <div className="w-full">
            <section className=" text-white overflow-x-hidden bg-[#EAEAEA]">
                {/* Blog Header */}
                <div className="w-11/12 mx-auto px-4 mb-10">
                    <h1 className="text-[64px] font-semibold text-[#1F1F1F] text-center max-md:text-[40px]">Blog</h1>
                </div>
                {/* Blog Section */}
                <motion.div
                    className="w-11/12 mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-6 pb-[10vh] max-md:flex max-md:overflow-x-scroll max-md:gap-4 max-md:pb-4"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible" // Triggers the animation when in viewport
                    viewport={{ once: true, amount: 0.2 }} // Animate once, 20% visibility
                >
                    {blogPosts.map((post, index) => (
                        <motion.div
                            key={post.id}
                            className="bg-white rounded-[10px] overflow-hidden cursor-pointer lg:min-w-[300px] max-md:min-w-full"
                            variants={cardVariants(index)}
                        >
                            <img
                                src={post.image}
                                alt={post.title}
                                className="w-full h-[400px] object-cover rounded-[12px]"
                            />
                            <div className="text-left p-4">
                                <h2 className="text-[#0F1121] text-[24px] font-semibold mb-2 max-md:text-[25px]">{post.title}</h2>
                                <p className="text-[#8E8D8D] text-[16px] font-light max-md:text-[14px]">{post.description}</p>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
                {/* Footer */}

            </section>
            <div className="w-full mx-auto bg-[#1F1F1F] text-white py-8 flex flex-col justify-between text-left items-start max-md:flex-col max-md:gap-6 max-md:px-4">
                <div className="w-11/12 flex flex-row justify-between mx-auto max-md:flex-col max-md:gap-6">
                    {/* Logo and Email */}
                    <div className="flex flex-col items-start justify-start">
                        <img src={footerLogo} alt="Logo" className=" h-[40px] object-fit" />
                        <p className="mt-2 max-md:text-[14px] lg:text-[#32px] font-bold text-left">
                            info@horusmediaworks.custom
                        </p>
                        <p className="text-[20px] font-regular">
                            Dijital dünyada güçlü bir varlık <br />oluşturmak için Horus Medya’ya ulaşın.
                        </p>
                    </div>
                    {/* Contact Info */}
                    <div>
                        <p className="font-light max-md:text-[14px]">
                            Gaziosmanpaşa, Büyükesat Mah, <br />Kaptanpaşa Sok. No:23/A, 06450 <br /> Çankaya/Ankara
                        </p>
                        <p className="mt-2 max-md:text-[14px] font-light">+90 123 456 789</p>
                    </div>
                </div>
                <img src={footerLine} alt="Footer Line" className="w-11/12 mx-auto h-[2px] object-fit my-4" />
                <div className="w-11/12 flex flex-row justify-between mx-auto max-md:flex-col max-md:gap-6">
                    {/* Logo and Email */}
                    <div className="flex flex-col items-start justify-start">
                        <p className="text-[16px] font-regular">
                            © 2024 Horus Medya. Tüm hakları saklıdır.
                        </p>
                    </div>
                    {/* Contact Info */}
                    <div className="flex flex-row gap-1 text-[16px] font-light">
                        <button>
                            <p className="text-[16px]"> Koşullar ve Politikalar</p>
                        </button>
                        <p>|</p>
                        <button>
                            <p className="font-light"> Gizlilik Politikası</p>
                        </button>
                        <div className="flex flex-row gap-1 lg:pl-10">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF />
                            </a>
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin />
                            </a>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
export default Footer;