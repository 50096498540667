import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import logo from "./Assets/logo.svg";
import menuIcon from "./Assets/hamburgerIcon.svg";
import closeIcon from "./Assets/closeIcon.svg";
import NavbarLogo from "./Assets/NavbarLogo.svg";
import navbarLine from "./Assets/navbarLine.svg";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <div className="relative w-screen">
            <NavbarDefault toggleMenu={toggleMenu} />
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        initial={{ y: "-100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "-100%" }}
                        transition={{ type: "spring", damping: 20, stiffness: 100 }}
                        className="fixed top-0 left-0 w-full h-full z-30 bg-white"
                    >
                        <NavbarOpened toggleMenu={toggleMenu} />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const NavbarDefault = ({ toggleMenu }) => (
    <div className="fixed top-0 z-20 flex items-center bg-transparent max-md:p-2 w-full py-4 px-8">
        {/* Left: Logo */}
        <div className="flex-1">
            <Link to="/">
                <img
                    src={logo}
                    alt="Logo"
                    className="w-[200px] h-[70px] object-contain max-md:w-[80px] max-md:h-[35px]"
                />
            </Link>
        </div>

        {/* Right: Menu Button */}
        <div className="flex-1 flex justify-end mr-4 gap-2">
            <Link
                to="/catalog"
                className="max-md:hidden rounded-lg bg-[#E70000] text-white text-[16px] flex items-center justify-center max-md:w-[100px] max-md:h-[30px] max-md:text-[12px] px-[10px] py-3"
            >
                <p>Katalog</p>
            </Link>
            <button onClick={toggleMenu} className="focus:outline-none">
                <img
                    src={menuIcon}
                    alt="Menu Icon"
                    className="w-[60px] h-[60px] object-contain max-md:w-[40px] max-md:h-[40px]"
                />
            </button>
        </div>
    </div>
);


const NavbarOpened = ({ toggleMenu }) => {
    // This function closes the menu and scrolls to the section with the given id.
    const handleNavItemClick = (sectionId) => {
        toggleMenu(); // Close the navbar
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full z-30 bg-[#1F1F1F] max-md:pt-4 max-md:h-screen ">
            <div className="absolute lg:top-[8vh] left-0 w-full z-30 max-md:top-0 ">
                <div className="bg-[#1F1F1F] mx-auto w-full px-3 p-8 max-md:p-4 max-md:pt-16 max-md:h-screen">
                    {/* Navbar header */}
                    <div className="fixed top-0 left-0 w-full p-4 z-30 flex items-start bg-[#1F1F1F] max-md:p-3">
                        <Link to="/" className="flex-1">
                            <img
                                src={NavbarLogo}
                                alt="Logo"
                                className="h-fit object-fill max-md:w-[100px]"
                            />
                        </Link>

                        <Link
                            to="/catalog"
                            className="absolute left-1/2 transform -translate-x-1/2 rounded-lg bg-[#E70000] text-white text-[16px] flex items-center justify-center px-[10px] py-3 max-md:hidden"
                        >
                            Katalog
                        </Link>

                        <button onClick={toggleMenu} className="text-right focus:outline-none">
                            <img
                                src={closeIcon}
                                alt="Close Icon"
                                className="w-[40px] h-[40px] object-contain max-md:w-[30px] max-md:h-[30px]"
                            />
                        </button>
                    </div>

                    {/* Navigation links */}
                    <nav className="flex flex-col items-center text-center mt-16 max-md:mt-12 max-md:h-1/3 ">
                        <div className="w-full flex flex-col items-center gap-4 max-md:gap-2 max-md:h-3/4 max-md:justify-between">
                            <button
                                onClick={() => handleNavItemClick("whyHorus")}
                                className="text-white text-[30px] hover:underline font-semibold max-md:text-[20px]"
                            >
                                Hakkımızda
                            </button>
                            <button
                                onClick={() => handleNavItemClick("services")}
                                className="text-white text-[30px] hover:underline font-semibold max-md:text-[20px]"
                            >
                                Hizmetlerimiz
                            </button>
                            <button
                                onClick={() => handleNavItemClick("portfolio")}
                                className="text-white text-[30px] hover:underline font-semibold max-md:text-[20px]"
                            >
                                İşlerimiz
                            </button>
                            <button
                                onClick={() => handleNavItemClick("horuseye")}
                                className="text-white text-[30px] hover:underline font-semibold max-md:text-[20px]"
                            >
                                İletişim
                            </button>
                        </div>

                        {/* Social links */}
                        <div className="flex justify-around mt-8 w-full max-md:mt-10">
                            <Link
                                to="/facebook"
                                className="text-[#E70000] underline text-[16px] max-md:text-[12px]"
                            >
                                Facebook
                            </Link>
                            <Link
                                to="/instagram"
                                className="text-[#E70000] underline text-[16px] max-md:text-[12px]"
                            >
                                Instagram
                            </Link>
                            <Link
                                to="/linkedin"
                                className="text-[#E70000] underline text-[16px] max-md:text-[12px]"
                            >
                                Linkedin
                            </Link>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};


export default Navbar;
