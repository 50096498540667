import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation, useScroll, useTransform, useMotionValue } from "framer-motion";
import PortfolioSection from "./PortfolioSection.jsx";
import { useInView } from "react-intersection-observer";
import WhyHorus from "./WhyHorus.jsx";
import MarqueeSection from "./MarqueeSection.jsx";
import Footer from "./Footer.jsx";
import Services from "./Services.jsx";
import HeroSection from "./HeroSection.jsx";
import HoruseyeSection from "./HoruseyeSection.jsx";
import Progress from "./Progress.jsx";

const Home = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ threshold: 0.2 });
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    return (
        <div className="">
            <div id="hero">
                <HeroSection />
            </div>
            <div id="marquee">
                <MarqueeSection />
            </div>
            <div id="whyHorus">
                <WhyHorus />
            </div>
            <div id="progress">
                <Progress />
            </div>
            <div id="horuseye">
                <HoruseyeSection />
            </div>
            <div id="services">
                <Services />
            </div>
            <div id="portfolio">
                <PortfolioSection />
            </div>
            <Footer />
        </div>
    );
};

export default Home;