import React, { useEffect, useState, useRef } from "react";
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    useVelocity,
    useAnimationFrame,
    useMotionValue,
} from "framer-motion";
import eye from "./Assets/eye.svg";

const wrap = (min, max, value) => {
    const range = max - min;
    return ((((value - min) % range) + range) % range) + min;
};

function ParallaxText({ children, baseVelocity = 100 }) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400,
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false,
    });

    const x = useTransform(baseX, (v) => `${wrap(-50, 0, v)}%`);

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();
        baseX.set(baseX.get() + moveBy);
    });

    return (
        <div className="parallax overflow-hidden whitespace-nowrap max-md:overflow-visible">
            <motion.div
                className="scroller flex"
                style={{ x }}
            >
                <span className="text-[90px] font-bold mx-4 my-2 max-md:text-[32px] max-md:mx-2">
                    {children}
                </span>
                <span className="text-[90px] font-bold mx-4 my-2 max-md:text-[32px] max-md:mx-2">
                    {children}
                </span>
                <span className="text-[90px] font-bold mx-4 my-2 max-md:text-[32px] max-md:mx-2">
                    {children}
                </span>
                <span className="text-[90px] font-bold mx-4 my-2 max-md:text-[32px] max-md:mx-2">
                    {children}
                </span>
            </motion.div>
        </div>
    );
}

const HoruseyeSection = () => {
    const [isBlinking, setIsBlinking] = useState(false);

    useEffect(() => {
        const blink = () => {
            setIsBlinking(true);
            const openEyeTimeout = setTimeout(() => setIsBlinking(false), 150);
            return openEyeTimeout;
        };

        const blinkInterval = setInterval(() => blink(), 3000);
        return () => clearInterval(blinkInterval);
    }, []);

    return (
        <>
            <section className="bg-white h-auto flex flex-col items-center justify-center overflow-x-hidden gap-[10vh] max-md:gap-[5vh] max-md:py-6">
                {/* Animated Eye */}

                <img src={eye} alt="Hero Section Image" className="lg:w-[140px] lg:h-[140px] max-md:w-[40px] max-md:h-[40px] object-cover ml-auto pt-3" />


                <p className="text-[#1F1F1F] text-[40px] font-bold w-3/5 text-center mt-4 max-md:text-[24px] max-md:w-4/5">
                    <span className="text-[#E70000]">Horus, </span>
                    Mısır mitolojisinde gökyüzünün koruyucusudur.
                </p>
                <p className="text-[#1F1F1F] text-[40px] font-bold w-3/5 text-center max-md:text-[24px] max-md:w-4/5">
                    Biz de markanızı dijital dünyada gökyüzüne yükseltiyoruz.
                </p>
            </section>
            <section className="py-10 w-full h-[600px] max-md:h-auto max-md:py-6 overflow-x-hidden text-[#EAEAEA]">
                <ParallaxText baseVelocity={-2}>
                    Markanızı dijital dünyada zirveye taşıyoruz. Başarıya kanat açın.
                </ParallaxText>
                <ParallaxText baseVelocity={2}>
                    Yenilikçi olun, ilham verin ve dijitalde yıldızlaşın.
                </ParallaxText>
                <ParallaxText baseVelocity={-2}>
                    Sosyal medyanın gücünü keşfedin, dünyada kalıcı iz bırakın.
                </ParallaxText>
            </section>
        </>
    );
};

export default HoruseyeSection;
