import React from "react";
import { StickyScroll } from "./ui/sticky-scroll-reveal";
import { ReactComponent as Image } from "./Assets/arrow.svg";

const content = [
    {
        title: "Keşif ve Analiz",
        description:
            "Markanızı, sektörünüzü ve hedef kitlenizi detaylı bir şekilde analiz ediyoruz. Bu süreçte ihtiyaçlarınızı belirleyerek, markanızın dijital dünyadaki yerini anlamaya odaklanıyoruz. Ayrıca, markanızın mevcut durumu ve potansiyeli üzerinde kapsamlı bir değerlendirme yapıyoruz. Böylece güçlü yönlerinizi vurgulayıp, geliştirilmesi gereken alanları belirleyerek stratejimizi buna göre şekillendiriyoruz.",
        content: (
            <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
                Collaborative Editing
            </div>
        ),
    },
    {
        title: "Strateji ve Planlama",
        description:
            "Size özel, hedeflerinize uygun yaratıcı stratejiler geliştiriyoruz. Tüm süreci detaylı bir planlama ile destekleyerek hedef kitlenizi en etkili şekilde yakalamanızı sağlıyoruz. Bu planlama sürecinde, dijital dünyada öne çıkmanız için en etkili araçları belirliyor ve uygulama aşamasında nasıl bir yol izleneceğini netleştiriyoruz. Hedeflerinize ulaşmanızı sağlayacak adımları en ince ayrıntısına kadar tasarlıyoruz.",
        content: (
            <div className="h-full w-full flex items-center justify-center text-white">
                <Image
                    src="/linear.webp"
                    width={300}
                    height={300}
                    className="h-full w-full object-cover"
                    alt="linear board demo"
                />
            </div>
        ),
    },
];

function Progress() {
    return (
        <div>
            <StickyScroll content={content} />
        </div>
    );
}
export default Progress;