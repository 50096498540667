import React, { useState } from 'react';
import batida from "./Assets//Brands/batida.svg";
import hamilton from "./Assets//Brands/hamilton.svg";
import loctite from "./Assets//Brands/loctite.svg";
import powershot from "./Assets//Brands/powershot.svg";
import transalp from "./Assets//Brands/transalp.svg";
import Marquee from "react-fast-marquee";
import arrow from "./Assets/arrow.svg";
import glimp from "./Assets/Brands/glimp.svg";
import mapsan from "./Assets/Brands//mapsan.svg";
import koza from "./Assets/Brands//koza.svg";
import mola from "./Assets/Brands//mola.svg";
import medi from "./Assets/Brands//medi.svg";
import ror from "./Assets/Brands//ror.svg";
import { Link } from 'react-router-dom';
const MarqueeSection = () => {
    const brands = [batida, hamilton, loctite, powershot, transalp, glimp, mapsan, koza, mola, medi, ror];
    const [hoveredBrand, setHoveredBrand] = useState(null);
    return (
        <section className="relative py-2 overflow-x-hidden ">
            <div className="relative py-10">
                {[0].map((rowIndex) => (
                    <div key={rowIndex} className="mb-10">
                        <Marquee
                            gradient={false} // Disable gradient
                            speed={90} // Set the same speed for all rows
                            direction="left" // All rows scroll in the same direction
                            pauseOnHover={true} // Let the images move even on hover
                        >
                            {[...brands, ...brands].map((brand, index) => (
                                <div
                                    key={`${rowIndex}-${index}`}
                                    className="relative cursor-pointer flex-shrink-0 mx-4"
                                    onMouseEnter={() => setHoveredBrand({ row: rowIndex, index })}
                                    onMouseLeave={() => setHoveredBrand(null)}
                                >
                                    {/* Base Brand Image */}
                                    <img
                                        src={brand}
                                        alt={`Brand ${index}`}
                                        className="w-auto max-md:h-[50px] h-[92px] object-contain"
                                    />

                                    {/* Overlay image, always rendered but hidden until hovered */}
                                    <div
                                        className={`absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out transform 
              ${hoveredBrand?.row === rowIndex && hoveredBrand?.index === index
                                                ? "opacity-100 translate-y-0"
                                                : "opacity-0 translate-y-4"
                                            }`}
                                    >
                                        <img
                                            src={brand}
                                            alt={`Brand ${index}`}
                                            className="w-auto max-md:h-[50px] h-[92px] object-contain hover:scale-105"
                                        />
                                    </div>
                                </div>
                            ))}
                        </Marquee>
                    </div>
                ))}
            </div>


            <div className="flex flex-row items-center justify-between px-[5vw] py-10 bg-white max-md:flex-col max-md:justify-center max-md:gap-6">

                {/* Text Content */}
                <div className="w-3/5 max-md:w-full max-md:text-center">
                    <p className="font-poppins font-bold text-[64px] text-left lg:leading-[56px] tracking-[-0.03em] mb-6 max-md:text-[40px]  max-md:text-center">
                        <span className="text-[#E70000]">Hedefimiz, markanızı</span> <br />
                        <span className="text-[#E70000]">dijitalde</span> <span className="text-black">yıldızlaştırmak!</span>
                    </p>

                    <p className="font-poppins font-light text-[24px] text-left leading-[28px] tracking-[-0.03em] text-[#8E8D8D] pl-[50px] max-md:pl-10 max-md:text-[16px] max-md:leading-[24px]">
                        Müşterilerimize dijital varlıklarını güçlendirme yolunda tam destek veriyoruz. Sağlam
                        stratejiler ve yaratıcı dokunuşlarla markanızın hikayesini etkileyici bir şekilde anlatıyor,
                        dijital dünyada sürdürülebilir başarıyı yakalamanız için yanınızda duruyoruz.
                    </p>
                </div>

                {/* Arrow Icon */}
                <button className="w-[292px] h-[292px] border-[2px] border-[#231f1f] rounded-full flex justify-center items-center max-md:w-[100px] max-md:h-[100px] max-md:ml-auto"
                    Link to="/catalog">
                    <img src={arrow} alt="Arrow Icon" className="w-[100px] h-[87px] object-contain max-md:w-[40px] max-md:h-[30px]" />
                </button>

            </div>

        </section>
    );
}
export default MarqueeSection;