"use client";
import React, { useEffect, useRef, useState } from "react";
import { useMotionValueEvent, useScroll, motion } from "framer-motion";
import { cn } from "../lib/utils";

export const StickyScroll = ({ content, contentClassName }) => {
    const [activeCard, setActiveCard] = useState(0);
    const ref = useRef(null);

    const { scrollYProgress } = useScroll({
        container: ref,
        offset: ["start start", "end end"],
    });

    const cardLength = content.length;

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        const cardsBreakpoints = content.map((_, index) => index / cardLength);
        const closestBreakpointIndex = cardsBreakpoints.reduce(
            (acc, breakpoint, index) => {
                const distance = Math.abs(latest - breakpoint);
                return distance < Math.abs(latest - cardsBreakpoints[acc]) ? index : acc;
            },
            0
        );
        setActiveCard(closestBreakpointIndex);
    });

    // Background color & gradient management (if needed)
    const backgroundColors = ["#EAEAEA"];
    const linearGradients = [
        "linear-gradient(to bottom right, var(--cyan-500), var(--emerald-500))",
        "linear-gradient(to bottom right, var(--pink-500), var(--indigo-500))",
        "linear-gradient(to bottom right, var(--orange-500), var(--yellow-500))",
    ];

    const [backgroundGradient, setBackgroundGradient] = useState(
        linearGradients[0]
    );

    useEffect(() => {
        setBackgroundGradient(
            linearGradients[activeCard % linearGradients.length]
        );
    }, [activeCard]);

    return (
        <motion.div
            // Animate the background color
            animate={{
                backgroundColor:
                    backgroundColors[activeCard % backgroundColors.length],
            }}
            // Use h-screen to fill the browser height
            className="h-[100vh] w-screen overflow-y-auto flex justify-center relative max-md:h-full max-md:w-full
            max-md:flex-col max-md:items-center"
            ref={ref}
        >
            {/* Progress Bar at Top Left (fixed positioning for constant visibility) */}
            <motion.div
                className="fixed top-0 left-0 h-2 bg-[#E70000] origin-left z-50 max-md:hidden"
                style={{ scaleX: scrollYProgress, width: "100%" }}
            />

            {/* Sticky block on the LEFT */}
            <div
                className={cn(
                    "block h-60 w-fit rounded-md sticky top-10 overflow-hidden mr-4"
                )}
            >
                <p className="text-[24px] max-md:text-[24px] text-[#E70000] font-semibold">
                    Süreçlerimiz
                </p>
                {content.map((item, index) => (
                    <motion.p
                        key={item.title + index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={
                            activeCard === index
                                ? { opacity: 1, y: 0 }
                                : { opacity: 0.3, y: 0 }
                        }
                        transition={{ duration: 0.5 }}
                        className="text-[50px] max-md:text-[28px] text-[#1F1F1F] font-semibold"
                    >
                        {item.title}
                    </motion.p>
                ))}
            </div>

            {/* Scrollable text content on the RIGHT */}
            <div className="relative flex items-start px-4 text-right">
                <div>
                    {content.map((item, index) => (
                        <div key={item.title + index} className="lg:my-20">
                            <motion.p
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: activeCard === index ? 1 : 0.3,
                                }}
                                className="text-[40px] text-[#1F1F1F] max-w-[50vw] lg:mt-10 font-light max-md:text-[16px] text-left max-md:mt-4"
                            >
                                {item.description}
                            </motion.p>
                        </div>
                    ))}
                    <div className="h-40" />
                </div>
            </div>
        </motion.div>
    );
};
